$(function () {

    if ($(window).width() <= 1000) {
        $('.nav-slider').slick({
            arrows: false,
            centerMode: true,
            infinite: false,
            dots: false,
            responsive: [
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 3,
                        centerPadding: '20px',
                    },
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        centerPadding: '70px',
                    }
                }
            ]
        });
    }

    var $carousel = $('.carousel');
    if ($carousel.length == 0) return false;

    $carousel.on('init', function (slick) {
        fadeOutMask();
        setTimeout(function () {
            $carousel.addClass('slider-loaded');
        }, 500);
    });

    $carousel.slick({
        autoplay: false,
        autoplaySpeed: 4000,
        dots: false,
        touchThreshold: 25,
        centerPadding: 0,
        arrows: true,
        onAfterChange: function () {
            var $play = $(this).find('.play-button');  
            if ($play.hasClass('playing')) {
                $('.carousel video').each(function () {
                    $(this).get(0).pause();
                    $play.removeClass('playing');
                });
            }
        }
    });

    var initialDelay = 4000;
    setTimeout( function() {
        $carousel.slick("slickPlay");
    }, initialDelay);

    $carousel.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        var $playbtn = $(this).find('.play-button');  
        if ($playbtn.hasClass('playing')) {
            $(this).find('video').get(0).pause();
            $playbtn.removeClass('playing');
        }
    });

    $carousel.on('afterChange', function (event, slick, currentSlide, nextSlide) {
        fadeOutMask();
    });

    function fadeOutMask() {
        var $active = $carousel.find('.slick-active');
        $active.find('.mask.action-fade-out').removeClass('action-fade-out');
    }

    $carousel.find('.slick-prev').append('<i class="icon-arrow-left"></i>');
    $carousel.find('.slick-next').append('<i class="icon-arrow-right"></i>');


    $carousel.find('video').each(function () {
        var hd = $(this).attr('data-hd');
        var sd = $(this).attr('data-sd');
        var mobile = $(this).attr('data-mobile');

        if ($(window).width() > 1000) {
            var video = (hd !== '' ? hd : (sd !== '' ? sd : (mobile !== '' ? mobile : '')));
        }
        else if ($(window).width() > 767) {
            var video = (sd !== '' ? sd : (hd !== '' ? hd : (mobile !== '' ? mobile : '')));
        }
        else {
            var video = (mobile !== '' ? mobile : (sd !== '' ? sd : (hd !== '' ? hd : '')));
        }
        $(this).attr('src', video);
    }).on('ended', function () {
        $('.play-button').removeClass('playing');
    });


    var resizeListener = function () {
        $(window).one('resize', function () {
            onWindowResize();
            setTimeout(resizeListener, 100);
        });
    };
    $(window).on("orientationchange", function (event) {
        onWindowResize();
    });

    onWindowResize();
    resizeListener();
    
    function onWindowResize() {
        if ($(window).width() < 1450) {
            $('.carousel video').css('margin-left', -(($('.carousel video').width() / 2) - $('.intro-video').width() / 2));
        } else {
            $('.carousel video').css('margin-top', -(($('.carousel video').height() / 2) - $('.intro-video').height() / 2));
        }

    }

    $('.play-button').click(function () {
        $(this).toggleClass('playing');
        $(this).siblings('video').addClass('playing');
        $(this).parent().addClass('show-video');
        $(this).siblings('video').get(0).play();
        setTimeout(function () {
            $('.play-button').addClass('playFade');
        }, 1000);
        $carousel.slick('slickPause')
    });

   

    $('video').click(function () {
        if ($(this).hasClass('playing')) {
            $(this).get(0).pause();
            $(this).siblings('.play-button').removeClass('playing');
            $(this).siblings('.play-button').removeClass('playFade');
        }
    });

    $('.audio-button').click(function () {
        $(this).toggleClass('muted');

        if ($(this).hasClass('muted')) {
            $('.hero .carousel video').each(function () {
                $(this).get(0).muted = true;
            })
        }
        else {
            $('.hero .carousel video').each(function () {
                $(this).get(0).muted = false;
            })
        }
    });

    $carousel.find('.carousel__scroll').on('click', function () {
        var nextElement = $carousel.next();

        $('html, body').animate({
            scrollTop: nextElement.offset().top
        });
    });
});


$(function () {

    var $container = $('.grid-carousel');
    var $inline = $('.inline-carousel');
    var $full = $('.full-carousel');

    if ($inline.length == 0 || $full.length == 0) return false;

    $inline.slick({
        dots: true,
        autoplay: true,
        touchThreshold: 25,
        arrows: false,
        asNavFor: $full
    });

    $full.slick({
        dots: true,
        autoplay: true,
        touchThreshold: 25,
        arrows: false,
        asNavFor: $inline
    });

    $('.grid-carousel .expand-carousel, .grid-carousel .contract-carousel').click(function () {

        var $thisContainer = $(this).closest('.grid-carousel');

        if (!$thisContainer.hasClass('fixed')) {
            var scroll = (($thisContainer.offset().top - ($(window).height() / 2)) + ($thisContainer.height() / 2));
            $('html, body').animate({ scrollTop: scroll }, 400, function () {
                $thisContainer.addClass('fixed');
            });
            $('#siteWrap').addClass('lock');
        }
        else {
            $thisContainer.removeClass('fixed');
            $('#siteWrap').removeClass('lock');
        }
    });
});

$(function () {
    var slideCount = $('.awards-carousel img').length 
    var slickSlides = (slideCount > 5) ? 5 : (slideCount > 0 ? slideCount : 1);

    $('.awards-carousel').slick({
        slidesToShow: slickSlides,
        slidesToScroll: slickSlides,
        touchThreshold: 50,
        dots: true,
        infinite: true,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    centerMode: true,
                    centerPadding: '5%'
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '20px'
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '10%'
                }
            }
        ]
    });
});


$(function () {

    var slideCount = $('.awards-carousel-landing img').length 
    var slickSlides = (slideCount > 3) ? 3 : (slideCount > 0 ? slideCount : 1);
    var slickMSlides = (slickSlides === 1) ? 1 : 2
    

    $('.awards-carousel-landing').slick({
        slidesToShow: slickSlides,
        slidesToScroll: slickSlides,
        touchThreshold: 50,
        dots: true,
        infinite: true,
        centerPadding: '0px',
        centerMode: true,
        responsive: [
            {
                breakpoint: 1250,
                settings: {
                    slidesToShow: slickMSlides,
                    slidesToScroll: slickMSlides,
                    centerMode: true,
                    centerPadding: '0'
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '0'
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '0'
                }
            }
        ]
    });

    if ($('.awards-carousel-landing img').length === 1) {
        $('.awards').addClass('has-one-slider')

        if ($( window ).width() > 800 ) {
            $('.letter').css('width', '50%')
            $('.awards').css('width', '50%')
        } else {
            $('.awards').css('width', '100%')
            $('.letter').css('width', '100%')
        }
    } 

   
});