(function ($) {

    var equalColumnsClass = function (elem, settings) {

        var parent = this;
        var imgCount = 0;
        var imgLoaded = 0;

        this.resize = function () {

            var breakPoint = 0;
            for (point in settings['breakpoints']) {
                if ($(window).width() > point) {
                    breakPoint = point;
                }
            }
            var columns = settings['breakpoints'][breakPoint];

            var elems = new Array;
            var curr = 0;
            var count = $(elem).children().filter(':visible').length;

            var $imgs = $(elem).find('img');

            imgCount = $imgs.length;
            $imgs.each(function () {
                var image = new Image();
                image.onload = function () {
                    imgLoaded++;
                    if (imgLoaded == imgCount) {
                        parent.resize(elem, settings);
                    }
                }
                image.src = $(this).attr('src');
            });

            $(elem).children().filter(':visible').each(function () {
                elems.push($(this));
                curr++;
                if (elems.length == columns) {
                    parent.performResize(elems, columns);
                    elems = new Array();
                }
                else if (curr == count) {
                    parent.performResize(elems, columns);
                }
            });
        };

        this.performResize = function (elems, columns) {
            var maxHeight = 0;
            for (var i = 0; i < elems.length; i++) {
                $(elems[i]).height('auto');
                if ($(elems[i]).outerHeight() > maxHeight) {
                    maxHeight = $(elems[i]).outerHeight();
                }
            }
            if (columns == 1) { return false; }
            for (var i = 0; i < elems.length; i++) {
                $(elems[i]).height(maxHeight);
            }
        };

        this.resize();

        this.listener = function () {
            $(window).one('resize', function () {
                parent.resize(elem, settings);
                setTimeout(parent.listener, 100);
            });
        };
        $(window).on('orientationchange', function (event) {
            parent.resize(elem, settings);
        });
        this.listener();
    };


    $.fn.equalColumns = function (options) {
        var opts = $.extend({}, $.fn.equalColumns.defaults, options);
        return this.each(function () {
            new equalColumnsClass($(this), opts);
        });
    };

    $.fn.equalColumns.defaults = {
        breakpoints: {
            0: 1
        }
    };

})(jQuery);