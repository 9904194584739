$(function () {

    $('.read-more').each(function () {
        var $this = $(this);

        $this.wrapInner('<div class="read-more-content"></div>');
        $this.append('<button class="read-more-button"><span>Read More</span><span>Show Less</span><i class="icon-arrow-right"></i></button>');

        var $content = $this.find('.read-more-content');

        $('.read-more').find('.read-more-button').click(function () {
            $this.toggleClass('expanded');
            if ($this.hasClass('expanded')) {
                $content.slideDown();
            }
            else {
                $content.slideUp();
            }
        });
    });

    $('.book-strip input[type=text]').click(function () {
        var bookStrip = $(this).closest('.book-strip');
        if (!bookStrip.hasClass('active')) {
            bookStrip.addClass('active');
        }
        $(this).parent().parent().siblings().find('.date-picker').fadeOut();
        $(this).siblings('.date-picker').fadeIn();
        $(this).siblings('.date-picker').datepicker({ minDate: $(this).val() });
    });

    var gaDone = false;
    $('.book-strip button').on('click', function (e) {
        if (gaDone == false) {
            e.preventDefault();
            if (typeof ga !== 'undefined') {
                var clientId = ga.getAll()[0].get('clientId');
                $('.book-strip input[name="bookingModel._ga"]').val(clientId).change();
            }
            gaDone = true;
            $(this).trigger('click');
        }
    });
});