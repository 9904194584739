$(function () {

    /* Set Visible Menus */
    var $primaryNav = $('.primary-nav');
    var $currentPage = $primaryNav.find('.current-page');

    // Create a varible which adds class to parent of current page unless top level then add class to that li
    //var $currentParent = $primaryNav.find('.current-page').closest()
    var $backButton = $('#PrimaryNavBack');

    $currentPage.parentsUntil('.primary-nav').addClass('visible');
    var $ul = $currentPage.closest('li').find('ul');
    var $parentPage = $currentPage.closest('a');

    var ppLink = $parentPage.attr('href');
    var ppText = $parentPage.text();

    if ($ul.length > 0) {
        $ul.addClass('visible');
        if ($ul.attr('data-level') > 1) {
            $primaryNav.attr('data-level', $ul.attr('data-level'));
            $backButton.addClass('visible');
        }        
    }
    else {
        var $ul = $currentPage.closest('ul');
        if ($ul.attr('data-level') > 1) {
            $primaryNav.attr('data-level', $ul.attr('data-level'));
            $backButton.addClass('visible');
        }
    }

    $('#PrimaryNavBack a').attr('href', ppLink).text(ppText);

    var resizeTimer;
    var mainHeight = $('main').height();

    function desktopNavigation() {
        if ($(window).width() > 1000) {
            $(".primary-nav > li").hoverIntent({
                over: DNHoverIn,
                timeout: 400,
                interval: 100,
                out: DNHoverOut
            });
     
        }
    };



    if ($('html').hasClass('touch')) {
        if ($(window).width() > 768) {
            $('.primary-nav > li > a').click(function (e) {

                $('.primary-nav > li').each(function () {
                    $(this).addClass('nav-not-active');
                    $(this).removeClass('nav-active');
                });

                $(this).parent().addClass('nav-active');

                $('.primary-nav > li.nav-not-active > a').each(function () {
                    $(this).removeClass('active-link');
                });

                if (!$(this).hasClass('active-link') && $(this).siblings('ul').length) {

                    var href = $(this).attr('href');
                    var linkText = $(this).html();

                    e.preventDefault();
                    NavSlideIn($(this).parent());

                    $(this).addClass('active-link');
                    if (!$(this).siblings('ul').find('li.title-link').length) {
                        $(this).siblings('ul').prepend('<li class="title-link"><a href="' + href + '">' + linkText + '</a></li>');
                    }
                }
            });
        } 
    } else {
        desktopNavigation()
    }

    function NavSlideIn(obj) {
        obj.addClass('nav-active');
        $('.primary-nav > li > a').each(function () {
            if (!$(this).parent().hasClass('nav-active')) {
                $(this).parent().addClass('nav-not-active');
            }
        });
    }

    function NavSlideOut(obj) {
        obj.removeClass('nav-active');
        //$('.nav-bg').removeClass('active');
        $('.primary-nav > li > a').each(function () {
            if (!$(this).parent().hasClass('nav-active')) {
                $(this).parent().removeClass('nav-not-active');
            }
        });
    }

    function DNHoverIn() {
        NavSlideIn($(this))
    }

    function DNHoverOut() {
        NavSlideOut($(this))
    }

   
    $('.primary-nav > li > a').each(function () {
        if ($(this).siblings('ul').length) {
            $(this).addClass('level-2');
        }
    });
    

    if ($(window).width() < 1000) {
        $('.current-page').siblings().find('ul').hide()
        //$currentPage.siblings('ul li ul').hide();
    }

    var currentLevel = parseInt($primaryNav.attr('data-level'));
    if (currentLevel == 2) {
        $currentPage.siblings('ul').addClass('active');
        $currentPage.parent().addClass('active');

    } else if (currentLevel == 3) {
        $currentPage.parent().parent('ul').addClass('active');
        $currentPage.closest('ul').parent('li').addClass('active');

    }
    
    //var navHeight =  $('.nav-wrap').height()

 



    /* Tablet Navigation */

    if ($(window).width() < 1000 && $(window).width() > 768) {
        var subNav = $('.sub-nav');
        var subNavLi = $('.sub-nav li');
        var SNWidth = 0;
        var tabletSubNav = $primaryNav.find('ul.level-2.visible').html();
        //var tabletSubNav = $primaryNav.find('li > a.current-page').siblings('ul').html();
        $('.sub-nav ul').html(tabletSubNav);

        if ($('.sub-nav li').length) {
            subNav.addClass('active');
            // TO DO - Check if the width of the lis is more than the width of the ul and add prompt arrow at the far right //
        }

    }

    function NavigationPosition() {
        var ContentHeight = $('main').height() + $('footer').height();
        var navHeight = $('#TopNav').outerHeight() + $('#SideNav .logo').outerHeight() + $('#SideNav .nav-wrap').outerHeight() + $('#SideNav .fixed-bottom').outerHeight();

        if (navHeight < $(window).height()) {
            $('#SideNav').addClass('fixed');
        } else {
            $('#SideNav').height(ContentHeight);
            $('#SideNav').removeClass('fixed');
        }
    }
   
    NavigationPosition();

    /* Change to fixed position if nav is small enough */

    $(window).on('resize', function (e) {

        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {

            NavigationPosition()

        }, 250);

    });





    ///* Mobile Click Events */
    //var subNavTimeout = null;

    //if ($(window).width() < 768) {

    //    $('.primary-nav > li  ').click(function (e) {
    //        if ($(this).hasClass('fade-links')) {
    //            e.preventDefault();
    //            hideSubNav($(this).parent());
    //        } else {
    //            e.preventDefault();
    //            showSubNav($(this).parent(), true);
    //        }
    //    });

    //} else {

    //    /* Desktop Mouse Events */
    //    $('.primary-nav .nav-item').mouseover(function () {
    //        var width = $(window).width();
    //        if (width > 767) {
    //            showSubNav($(this));
    //        }
    //    });

    //    $('.primary-nav').mouseleave(function () {
    //        var width = $(window).width();
    //        if (width > 767) {
    //            hideSubNav($(this));
    //        }
    //    });
    //}



    //function hideSubNav($this) {
    //    clearTimeout(subNavTimeout);
    //    subNavTimeout = null;

    //    var $subNav = $this.find('.sub-nav');
    //    if ($subNav.length > 0) {
    //        $subNav.removeClass('sub-nav-visible').stop().slideUp();
    //    }

    //    toggleSubNavClass(); showSubNav
    //}

    //function showSubNav($this, mobile) {
    //    clearTimeout(subNavTimeout);
    //    subNavTimeout = null;

    //    var $subNav = $this.find('.sub-nav');
    //    if ($subNav.length > 0) {

    //        var $active = $('.primary-nav .sub-nav-visible');
    //        if ($active.length > 0) {
    //            if ($this.index() !== $active.parent().index()) {
    //                $active.removeClass('sub-nav-visible').stop().slideUp(300);
    //                subNavTimeout = setTimeout(function () {
    //                    $subNav.addClass('sub-nav-visible').stop().slideDown();
    //                    toggleSubNavClass();
    //                }, 200);
    //            }
    //            else {
    //                if (typeof mobile !== 'undefined' && mobile) {
    //                    $active.removeClass('sub-nav-visible').stop().slideUp();
    //                }
    //            }
    //        }
    //        else {
    //            $subNav.addClass('sub-nav-visible').stop().slideDown();
    //        }
    //    }

    //    toggleSubNavClass();
    //}

    //function toggleSubNavClass() {
    //    var $primaryNav = $('.primary-nav');
    //    if ($primaryNav.find('.sub-nav-visible').length > 0) {
    //        $primaryNav.addClass('fade-links');
    //    }
    //    else {
    //        $primaryNav.removeClass('fade-links');
    //    }
    //}




    /* Back Button */
    $('#PrimaryNavBack i.back').click(function (e) {
        e.preventDefault();

        var currentLevel = parseInt($primaryNav.attr('data-level'));
        if (currentLevel > 1) {
            $primaryNav.attr('data-level', currentLevel - 1);
        }

        if (currentLevel == 2) {
            //$(this).removeClass('visible');
            $(this).parent().removeClass("visible");
        }

        if ($primaryNav.attr('data-level') != $ul.attr('data-level')) {
            $('#PrimaryNavBack i.forward').show();
        }

  
 
        //var ppLink = $parentPage.attr('href');
        var UpdatedBtnLink = $('.primary-nav').find('ul.visible').children('a').attr('href');
        var UpdatedBtnText = $('.primary-nav').find('ul.visible').children('a').text();
        var $dataLevel = $primaryNav.attr('data-level');
        var $menuLevel = $primaryNav.find("ul.level-" + $dataLevel);


        /* Switch Link */
        //$('.primary-nav ul').each(function () {
        //    if ($(this).attr('data-level') == $('.primary-nav').attr('data-level') && $(this).hasClass('visible')) {
        //        var backText = $(this).find('li > a').text();
        //        $('#PrimaryNavBack a').text(backText)
        //    }
        //});

        /* Hide Back Link */

        $('#PrimaryNavBack i').siblings('a').fadeOut();



        if ($(window).width() < 768) {

            var ParentNavHeight = 0;
            var sum = 0;

            $currentPage.closest('ul').children('li').each(function () {
                sum += $(this).outerHeight(true);
                return parseInt(sum);
            });

            setTimeout(function () {
                $('.primary-nav').animate({ height: sum }, 400);
            });

            $primaryNav.attr('data-level', 1);
        }

    });


    /* Forward Button */
    $('#PrimaryNavBack i.forward').click(function (e) {
        e.preventDefault();

        var currentLevel = parseInt($primaryNav.attr('data-level'));
        if (currentLevel > 1) {
            $primaryNav.attr('data-level', currentLevel + 1);
        }

        if (currentLevel == 2) {
            //$(this).removeClass('visible');
            //$(this).parent().removeClass('visible');
        }


        if ($primaryNav.attr('data-level') == $ul.attr('data-level')) {
            $('#PrimaryNavBack i.forward').hide();
            $('#PrimaryNavBack a').fadeIn();
        }


        //if ($(window).width() < 768) {
        //    var ParentNavHeight = 0;
        //    var sum = 0;
        //    $currentPage.closest('ul').children('li').each(function () {
        //        sum += $(this).outerHeight(true);
        //        return parseInt(sum);
        //    });
        //    setTimeout(function () {
        //        $('.primary-nav').animate({ height: sum }, 400);
        //    });
        //}

    });


    /* If there is no slider breadcrumb is positioned below the intro content */
    //if (!$('.main-slider').length) {
    //    $('#Breadcrumb, .intro-text').addClass('no-slider');
    //}


    


});