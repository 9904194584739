$(function () {

    var $bookNow = $('#BookNow');
    var $forms = $bookNow.find('.tab-contain');

    /* Tabs */
    $bookNow.tabbed({
        initialTab: null,
        afterChange: function () {
            $tabsContainer = $bookNow.find('.tabs');
            var $tab = $tabsContainer.find('.visible');
            $tabsContainer.css('margin-left', -((33.33 * $tab.index()) - 33.33) + '%');
        }
    });

    $('#BookNow .tabs li.booking').click(function () {
        if ($('body').hasClass('touch')) {
            if ($(window).width() < 1280) {
                var bookFormHeight = $(window).height() - ($('#BookNow .tabs') + 45)
                //$('.siteWrap, body').addClass('lock');
                $('#BookNowForms').height(bookFormHeight);
            }
        } else {
            //$('.siteWrap, body').addClass('lock');
        }

        $('#BookNowForms').addClass('active');
        $(window).resize();

    });

    $('.mobile-close').click(function () {
        $('#BookNow').removeClass('visible');
        //$('.siteWrap, body').removeClass('lock');
    });

    $('.book-strip .date-picker:last').addClass('last');

    var $tabsContainer = $bookNow.find('.tabs');
    var $tabs = $tabsContainer.find('li');
    var count = $tabs.length - 1;
    $tabsContainer.css('width', 33.33 * count + '%').css('margin-left', '33.33%');
    $tabs.css('width', (100 / count) + '%');

    /* Form */
    $('#BookNow form').each(function () {
        var $steps = $(this).find('.steps').children();
        $steps.first().addClass('current-step');
        if ($steps.length > 0) {
            $(this).attr('data-steps', $steps.length).attr('data-step', 1);
        }
        if ($steps.length == 1) {
            $(this).addClass('last-step');
        }
    });

    /* Next Step */
    $('#BookNow .next').click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        var $form = $(this).closest('form');
        var steps = $form.attr('data-steps');
        var step = parseInt($form.attr('data-step'));
        var newStep = step + 1;
        if (step < steps) {
            $form.attr('data-step', newStep);
            $form.find('.current-step').removeClass('current-step');
            $form.find('.steps .row:nth-child(' + newStep + ')').addClass('current-step');
        }
        if (newStep == steps) {
            $form.addClass('last-step');
        }

        currentStepIsValid();
    });

    /* Input Change */
    $('#BookNow form input').change(function (e) {
        e.stopPropagation();
        var $value = $(this).closest('form').find('.summary .value[data-val="' + $(this).attr('name') + '"]');
        if ($(this).attr('type') == 'date') {
            var day = $.datepicker.formatDate('d', new Date($(this).val()));
            var monthYear = $.datepicker.formatDate('MM yy', new Date($(this).val()));
            $value.text($(this).val() == '' ? '--' : ordinal(day) + ' ' + monthYear);
        }
        else if($(this).attr('type') == 'number') {
            $value.text($(this).attr('data-name') == '' ? '--' : $(this).val());
        }
        else {
            $value.text($(this).attr('data-name') == '' ? '--' : $(this).attr('data-name'));
        }

        currentStepIsValid();
    });

    /* Change Value */
    $('#BookNow .summary .change').click(function (e) {
        e.preventDefault();
        var $form = $(this).closest('form');
        var newStep = ($(this).closest('li').index() + 1);
        $form.find('.current-step').removeClass('current-step');
        $form.attr('data-step', newStep);
        $form.find('.steps .row:nth-child(' + newStep + ')').addClass('current-step');
        if (newStep !== $form.data('steps')) {
            $form.removeClass('last-step');
        }
    });

    function currentStepIsValid() {
        var $form = $('#BookNow .tab-contain.visible form');
        var $summary = '';
        var $change = $form.find('.summary ul li:nth-child(' + $form.attr('data-step') + ') .change');

        var $next = $form.find('.summary .next, .summary .book');

        if ($form.find('.current-step input').val() !== '') {
            $next.removeAttr('disabled');
            $change.removeAttr('disabled');
        }
        else {
            $next.attr('disabled', 'disabled');
            $change.attr('disabled', 'disabled');
        }
    }

    /* Date Picker */
    $('#BookNow .date-picker').each(function () {
        $(this).datepicker({
            altField: $(this).next(),
            defaultDate: null,
            dateFormat: "yy-mm-dd",
            //dayNamesMin: ["M", "T", "W", "T", "F", "S", "S"],
            minDate: 0,
            onSelect: function (dateText) {
                $(this).next().change();
            }
        })
        .find('.ui-datepicker-current-day').removeClass('ui-datepicker-current-day');
        $(this).next().val('').change();
    });

    $('.book-strip .date-picker').each(function () {
        $(this).datepicker({
            altField: $(this).next(),
            defaultDate: null,
            dateFormat: "dd/mm/yy",
            //dayNamesMin: ["M", "T", "W", "T", "F", "S", "S"],
            minDate: 0,
            onSelect: function (dateText) {
                $(this).next().change();
                $(this).fadeOut();
                var dateVal = $(this).val();
                if (!$(this).hasClass('last')) {
                    $(this).closest('.room-form').siblings().find('.date-picker').fadeIn();
                    var ds = $(this).val().split("/");
                    var datePicked = new Date(ds[2], ds[1] - 1, ds[0]);
                    datePicked.setDate(datePicked.getDate() + 1);
                    $(this).closest('.room-form').siblings().find('.date-picker').datepicker("option", "minDate", datePicked);
                }
                $(this).siblings('input[type=date]').val(dateVal);
                $(this).siblings('input[type=text]').val(dateVal);
            }
        })
        .find('.ui-datepicker-current-day').removeClass('ui-datepicker-current-day');
        $(this).next().val('').change();
    });

    $('.filter-bar .date-placeholder').click(function () {
        $(this).siblings('.date-picker').addClass('active');
    })

    $('.filter-bar .date-picker').each(function () {
        $(this).datepicker({
            altField: $(this),
            defaultDate: null,
            dateFormat: "yy-mm-dd",
            //dayNamesMin: ["M", "T", "W", "T", "F", "S", "S"],
            minDate: 0,
            onSelect: function (dateText) {
                $(this).removeClass('active');
                $(this).siblings('label').hide()
                var dateVal = $(this).val();
                $(this).siblings('.date-placeholder').children('i').text(dateVal);
                $(this).siblings('input[type=date]').val(dateVal);
            }
        }).find('.ui-datepicker-current-day').removeClass('ui-datepicker-current-day');
     
        $(this).next().val('').change();
    });

    function ordinal(i) {
        var j = i % 10, k = i % 100;
        if (j == 1 && k != 11) return i + "st";
        if (j == 2 && k != 12) return i + "nd";
        if (j == 3 && k != 13) return i + "rd";
        return i + "th";
    }

    if ($(window).width() < 768) {

        $('.property-list .propery-booking-title').remove();

        $('.property-list').slick({
            arrows: false,
            centerMode: true,
            infinite: false,
            dots: false,
            responsive: [
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 3,
                        centerPadding: '20px',
                    },
                    breakpoint: 768,
                    settings: {
                        //centerMode: true,
                        slidesToShow: 1,
                        centerPadding: '20px',
                    }
                }
            ]
        });

        $('.tabs li').click(function (e) {
            e.stopPropagation();
            $(this).parent().addClass('active');
            $(window).trigger('resize');
        });

        if ($(window).width() < 768) {
            $('#BookNowForms .tab-contain').each(function () {
                $(this).prepend('<div class="close"><span class="icon-close"></span></div>');
            });
        }

        $('.tab-contain .close').click(function () {
            $('.tabs, #BookNowForms').removeClass('active');
            $('body').removeClass('mob-lock');
            $(this).parent().removeClass('visible');
        });
    }

    /* Switch contact information in Book now bar */
    $('.tab-contain .property-selector').click(function (e) {
        e.stopPropagation();
        var contactInfo = $(this).children('.contact-details').clone();
        $(this).parent().parent().find('.contact-container').html(contactInfo);
    });

    /* IE Fix for Checkbox issue */
    $('.property-selector .logo-radio').click(function (e) {
        e.stopPropagation();
        var nextCB = $(this).next('input[type=radio]');
        nextCB.prop('checked', 'checked').trigger('change');
        $(this).addClass('checked');

        //var $value = $(this).closest('form').find('.summary .value[data-val="' + $(this).next('label').attr('name') + '"]');
        //$value.text($(this).next('label').attr('data-name') == '' ? '--' : $(this).next('label').attr('data-name'));
    });

    // Pre select a property
    $('#BookNowForms section[data-pre-select]').each(function () {
        $(this).addClass('visible');
        var preSelect = $(this).data('pre-select');
        if (preSelect !== '') {
            var $input = $(this).find('input[value="' + preSelect + '"]');
            if ($input.length > 0) {
                $input.trigger('click');
                $(this).find('.next').trigger('click');
            }
            else {
                var preSelectRelated = $(this).data('pre-select-related');
                if (preSelectRelated !== '') {
                    var $input = $(this).find('input[value="' + preSelectRelated + '"]');
                    if ($input.length > 0) {
                        $input.trigger('click');
                        $(this).find('.next').trigger('click');
                    }
                }
            }
        }
        $(this).removeClass('visible');

    });

    /*$('.table-dd .links').equalColumns({
        breakpoints: {
            0: 1,
            768: 4
        }
    });*/

    $('.restaurantbook .booking-body .dp, .experiencebook .booking-body .dp').datepicker({
        dateFormat: "DD d MM yy",
        inline: true,
        onSelect: function (dateText) {
            var dateVal = $(this).val();
            $(this).closest('.booking-body').find('.date-summary').html('You are booking on <br/>' + dateVal);
        }
    });

    $('.restaurantbook #FindTable, .experiencebook #FindTable').click(function (e) {
        e.preventDefault();
        // Get the default link
        var link = $('#FindTable').attr("href");
        var date = $('.dp').datepicker("getDate");
        var time = $('#BookingTime option:selected').val();
        var people = $('#BookingPeople option:selected').val();
        var date = date.getFullYear() + '-' + (("0" + (date.getMonth() + 1)).slice(-2)) + '-' + date.getDate() + 'T' + time;
        var newLink = link + "&covers=" + people + "&datetime=" + date + "&searchdatetime=" + date + "&partysize=" + people;
        window.location.href = newLink;
    });

    $('.restaurantbook .booking-body select, .experiencebook .booking-body select').on('change', function () {
        $(this).parent().siblings('label').hide();
    })

    $('.restaurantbook .booking-body select, .experiencebook .booking-body select').each(function () {
        if ($(this).siblings('.cab-select-box').html() != "") {
            $(this).parent().siblings('label').hide();
        }
    });
});