(function ($) {

    var selectClass = function (elem, settings) {
        var $this = $(elem);

        this.isMobile = function () {
            var agent = navigator.userAgent || navigator.vendor || b.opera;
            return /iPhone|iPod|iPad|Silk|Android|BlackBerry|Opera Mini|IEMobile/.test(agent);
        };

        $this.addClass('cab-select-hidden');
        $this.wrap('<div class="cab-select"></div>');
        $this.after('<div class="cab-select-box"></div>');

        var $box = $this.next('div.cab-select-box');
        $box.text($this.children('option:selected').first().text());

        $this.change(function () {
            $this.parent().find('.cab-select-box').text($(this).find('option:selected').text());
        });

        if (this.isMobile()) {
            $this.parent().addClass('cab-select-mobile');
        }
        else {
            /* Generate Options List */
            var optionsCount = $this.children('option').length;
            var $list = $('<ul />', {
                'class': 'cab-select-options'
            }).insertAfter($box);
            for (var i = (settings.hideFirstOption == true ? 1 : 0) ; i < optionsCount; i++) {
                $('<li />', {
                    text: $this.children('option').eq(i).text(),
                    rel: $this.children('option').eq(i).val()
                }).appendTo($list);
            }
            var $options = $list.children('li');

            /* Toggle List Visability */
            $box.click(function (e) {
                e.stopPropagation();
                var $selected = $(this);
                $('div.cab-select-box.active').each(function () {
                    if ($(this).get(0) !== $selected.get(0)) {
                        $(this).removeClass('active').next('ul.cab-select-options').hide();
                        $(this).closest('.cab-select').removeClass('active');
                    }
                });
                var $opt = $(this).next('ul.cab-select-options');
                $opt.toggle();

                $(this).closest('.cab-select').toggleClass('active');

                var top = $(this).toggleClass('active').next('ul.cab-select-options')[0].getBoundingClientRect().top;
                var height = $(window).height();
                var safeHeight = ((height - top) - 50);
                $opt.css('max-height', safeHeight < 150 ? 150 : safeHeight);
            });

            /* Select Options */
            $options.click(function (e) {
                e.stopPropagation();
                $this.val($(this).attr('rel')).change();
                $list.hide();
                $box.removeClass('active');
                $(this).closest('.cab-select').removeClass('active');
            });

            /* Hide Options */
            $(document).click(function () {
                $box.removeClass('active');
                $box.closest('.cab-select').removeClass('active');
                $list.hide();
            });
        }

    };

    $.fn.cabSelect = function (options) {
        var opts = $.extend({}, $.fn.cabSelect.defaults, options);
        return this.each(function () {
            new selectClass($(this), opts);
        });
    };

    $.fn.cabSelect.defaults = {
        hideFirstOption: false
    };

})(jQuery);