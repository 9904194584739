$(function () {

    /* Remove Video if mobile */
    //var resizeListener = function () {
    //    $(window).one('resize', function () {
    //        onWindowResize();
    //        setTimeout(resizeListener, 100);
    //    });
    //};
    //$(window).on("orientationchange", function (event) {
    //    onWindowResize();
    //});

    //onWindowResize();
    //resizeListener();
   
    $('.fixed-bottom').waypoint(function (direction) {
        if (direction == 'down') {
            $(this).addClass('sticky');
        } else if (direction === 'up') {
            $(this).removeClass('sticky');
        }
    }, { offset: 'bottom-in-view' });

    $('.logo').waypoint(function (direction) {
        if (direction == 'down') {
            $('.nav-wrap, .logo').addClass('sticky');
        } else if (direction === 'up') {
            $('.nav-wrap, .logo').removeClass('sticky');
        }
    }, { offset: 70 });



    (function ($) {
        var resizeTimer;
        function resizeNavTabs() {
            if ($(window).height() < 870) {
                $('.fixed-bottom').waypoint(function (direction) {
                    if (direction == 'down') {
                        $(this).addClass('sticky');
                    } else if (direction === 'up') {
                        $(this).removeClass('sticky');
                    }
                }, { offset: 'bottom-in-view' });

                $('.logo').waypoint(function (direction) {
                    if (direction == 'down') {
                        $('.nav-wrap, .logo').addClass('sticky');
                    } else if (direction === 'up') {
                        $('.nav-wrap, .logo').removeClass('sticky');
                    }
                }, { offset: 70 });
            }
        };
        $(window).resize(function () {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(resizeNavTabs, 250);
        });

    })(jQuery);




    var $info = $('#IntelligentInfo');
    if (!$info.length) return false;
    var $container = $info.find('.container');
    
    $(window).scroll(function () {
        if ($info.hasClass('closed')) return false;
            
        if ($(window).scrollTop() > 500) {
            show();
        }
        else if ($info.hasClass('visible') && $(window).scrollTop() < 500) {
            setTimeout(function () {
                hide();
            }, 2000);
        }
    });


    function show() {
        $info.addClass('visible');
        if ($('.fixed-bottom').hasClass('sticky')) {
            $container.css('top', 65);
        } else {
            $container.css('top', $('.MobWrap').position().top);
        }


        setTimeout(function () {
            $('#SideNav').addClass('has-info');
        }, 800);

       
    }
    function hide() {
        $info.removeClass('visible').addClass('closed');
        $container.removeAttr('style');
        $('body').removeClass('lock');
        $('.video-container').remove();
    }

    $info.find('.open').click(function () {
        show();
    });
    $info.find('.hide').click(function () {
        hide();
    });

    $('.home-navigation').removeClass('sticky');




    

});