/*
 * --------------------------------------------------
 * jQuery Tabbed v1.0
 * Copyright 2015 Matt Ballington CAB Studios
 * www.cabstudios.co.uk
 * --------------------------------------------------
 */
(function($) {
    var tabbedClass = function($elem, settings) {
        var selected = settings.initialTab;
        var $children = $elem.children();
        var $tabs, $content;

        if (settings.tabs !== null && settings.contents !== null) {
            $tabs = $elem.find(settings.tabs);
            $content = $elem.find(settings.contents);
        } else if ($children.length == 2) {
            $tabs = $children.first();
            $content = $children.last();
        }

        var $tabsChildren = $tabs.children();
        var $contentChildren = $content.children();

        $tabsChildren.click(function() {
            settings.beforeChange();

            $tabs.find('.visible').removeClass('visible');
            $(this).addClass('visible');
            $content.find('> .visible').removeClass('visible');

            var tab = $content.find('>:nth-child(' + ($(this).index() + 1) + ')');
            if (typeof tab.attr('data-tablink') === 'undefined') {
                tab.addClass('visible');
            }

            selected = $(this).index();
            settings.afterChange();
        });

        // check for any direct links
        $tabsChildren.each(function() {
            var $tab = $(this);
            var $tabContent = $content.find('>:nth-child(' + ($(this).index() + 1) + ')');

            if ($tabContent.attr('data-tablink') != null && $tabContent.attr('data-tablink') != '') {
                // wrap tab title in a direct link
                $tab.html('<a href="' + $tabContent.attr('data-tablink') + '" target="' + $tabContent.attr('data-tabtarget') + '">' + $tab.text() + '</a>');
            }
        });

        if (settings.initialTab !== null) {
            $($tabsChildren[settings.initialTab]).addClass('visible');
            $($contentChildren[settings.initialTab]).addClass('visible');
        }

        $elem.on('prev', function() {
            $($tabsChildren[selected - 1]).trigger('click');
        });

        $elem.on('next', function() {
            $($tabsChildren[selected + 1]).trigger('click');
        });
    };

    $.fn.tabbed = function(options) {
        var opts = $.extend({}, $.fn.tabbed.defaults, options);
        return this.each(function() {
            new tabbedClass($(this), opts);
        });
    };

    $.fn.tabbed.defaults = {
        tabs: null,
        contents: null,
        initialTab: 0,
        beforeChange: function() {},
        afterChange: function() {}
    };
})(jQuery);
