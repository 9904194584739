$(document).ready(function () {
	$('a[href*="vimeo.com"]').each(function (i, el) {
		var link = $(el);

		// get video ID from the URL
		var regex = /(\d{5,})+(?=\/|$|\?)/g;

		var videoId = link.attr('href').match(regex);

		if (!videoId || videoId.length == 0) {
			// no video ID found
			return false;
		}

		link.fancybox({
			autoSize: false,
			width: "80%",
			height: "80%",
			padding: [5, 5, 5, 5],
			content:
				'<div id="video-pop">' +
				'<div class= "video-pop-wrapper">' +
				'<iframe src="https://player.vimeo.com/video/' + videoId[0] + '?autoplay=0&title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>' +
				'</div>' +
				'<script src="https://player.vimeo.com/api/player.js"></script>' +
				'</div>'
		});
	});

	$('a[href*="mediazilla.com"]').each(function(i, el) {
		var link = $(el);

		var videoId = link.attr('href')

		if (!videoId || videoId.length == 0) {
			// no video ID found
			return false;
		}

		link.fancybox({
			autoSize: false,
			width: "80%",
			height: "80%",
			padding: [5, 5, 5, 5],
			content:
				'<div id="video-pop">' +
					'<div class= "video-pop-wrapper">' +
						'<iframe src="' + videoId + '" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>' +
					'</div>' +
				'</div>'
		});
	})
});
