/* Popup Window */
$('.popup').click(function (e) {
    e.preventDefault();

    var width = 550,
    height = 420,
    left = ($(parent.window).width() - width) / 2,
    top = (($(parent.window).height() - height) / 2) - 210,
    url = this.href,
    opts = 'status=1' +
             ',width=' + width +
             ',height=' + height +
             ',top=' + top +
             ',left=' + left;
    parent.window.open(url, 'social', opts);
    return false;
});