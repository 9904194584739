$(function () {
    if ($('.offerslisting').length == 0) return false;


    var property = getParameterByName('property');
    var category = getParameterByName('category');


    $(window).resize(function () {
        if ($(window).width() > 767) {
            $('.offer').each(function () {
                EqualHeight($(this).children('.grid-image'), $(this).children('.grid-text'));
            });
        }
    });

    $('.property-stripe').each(function () {
        cbHeight = $(this).parent().height()
        $(this).height(cbHeight);
    });
    
    $('.offer').each(function () {
        if ($(window).width() > 767) {
            EqualHeight($(this).children('.grid-image'), $(this).children('.grid-text'));
        }
    });


    setDropdownValues(property, category);


    // FUNCTIONS
    
    function EqualHeight(image, text) {

        //var imageHeight = image.outerHeight();
        //var textHeight = text.outerHeight();

        //if (textHeight > imageHeight) {
        //    image.height(textHeight);
        //}
    }

    function setDropdownValues(property, category) {
        if (property != null && property != undefined && property != '') {
            if ($('.offers-filter select[id=property] option[value="' + property + '"]').length > 0) {
                $('.offers-filter select[id=property]').val(property);
                $('.offers-filter select[id=property] option').removeClass('selected');
                $('.offers-filter select[id=property] option[value="' + property + '"]').addClass('selected');
                $('.property-dropdown .cab-select-box').text($('.property-dropdown li[rel=' + property + ']').text())
            }
        }
        if (category != null && category != undefined && category != '') {
            $('.offers-filter select[id=categories]').val(category);
            $('.offers-filter select[id=categories] option').removeClass('selected');
            $('.offers-filter select[id=categories] option[value="' + category + '"]').addClass('selected');
            $('.category-dropdown .cab-select-box').text($('.category-dropdown li[rel=' + category + ']').text())
        }
    }
    
    function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

});


// OBSOLETE??

///* Add Tabs */
//for (var tab in tabs) {
//    $('.offerslisting .tabs').append('<li><a href="#' + tabs[tab] + '">' + tab + '</a></li>');
//}

///* Tabs */
//var $tabsContainer = $('.offerslisting .tabs');
//var $tabs = $tabsContainer.find('li');
//var width = 33.33;
//$tabsContainer.css('width', width * $tabs.length + '%').css('margin-left', width + '%');
//$tabs.css('width', (100 / $tabs.length) + '%');

///* Tab Swipe */
//$('.offerslisting nav').cabSwipe({
//    left: function () {
//        var $next = $tabsContainer.find('.visible').next();
//        if ($next.length > 0) {
//            $next.find('a')[0].click();
//        }
//    },
//    right: function () {
//        var $prev = $tabsContainer.find('.visible').prev();
//        if ($prev.length > 0) {
//            $prev.find('a')[0].click();
//        }
//    }
//});

///* Position Tabs */
//function positionTabs() {
//    var index = $tabsContainer.find('.visible').index();
//    if (index >= 0) {
//        $tabsContainer.css('margin-left', -((width * index) - width) + '%');
//    }
//}

///* Add margin to filter nav if no slider */
//if (!$('.main-slider').length) {
//    $('main nav').addClass('no-slider');
//}

//$(window).bind('hashchange', function () {
//    hashChange();
//});

//hashChange();

//function hashChange() {
//        $tabs.removeClass('visible');

//        var hash = window.location.hash.replace(/^#/, '');
//        if (hash == 'all' || hash == '') {
//            $('.offerslisting .category').each(function () {
//                if (!$(this).find("> div").hasClass("empty")) {
//                    $(this).addClass('visible');
//                } else {
//                    $(this).removeClass('visible');
//                }
//            });
//            $('.offerslisting .offer').show();
//            $tabs.first().addClass('visible');
//        }
//        else {
//            var $category = $('.offerslisting .category[data-hash="' + hash + '"]');

//            if ($category.length > 0) {
//                var $offers = $('.offerslisting .category[data-hash="' + hash + '"] .offer:not(.empty), .offerslisting .offer[data-hash~="' + hash + '"]');
//                if ($offers.length > 0) {
//                    $('.offerslisting .category .offer').hide();
//                    $('.offerslisting .category').each(function () {
//                        if (!$(this).find("> div").hasClass("empty")) {
//                            $(this).addClass('visible');
//                        } else {
//                            $(this).removeClass('visible');
//                        }
//                    });
//                    $offers.show();
//                }
//                else {
//                    $('.offerslisting .category').removeClass('visible');
//                    $('.offerslisting .offer').show();
//                    $category.addClass('visible');
//                }

//                $tabs.find('a[href="#' + hash + '"]').closest('li').addClass('visible');
//            }
//        }
//        positionTabs();
//    };