$(function () {
    if ($('.events-listing').length <= 0) return false;

    var property = getParameterByName('property');
    var selectedDate = getParameterByName('date');
    var $eventDatePicker = $('#events-date-picker')


    $(document).click(function (event) {
        // Close Date Picker When Clicked Outside
        if (!$(event.target).closest('#events-date-picker').length && !$(event.target).closest('.date-show').length) {
            if ($('#events-date-picker').hasClass('active')) {
                $eventDatePicker.hide().removeClass('active');
            }
        }
    });

    $('.date-show').click(function () {        
        $eventDatePicker.show().addClass('active');
    });


    // Setup Date Picker
    generateDatePicker();

    // Set the dropdown values to the querystring values.
    setDropdownValues(property);
    setDatePickerValue();


    // FUNCTIONS

    function generateDatePicker() {
        $('.events-filter #events-date-picker').datepicker({
            dateFormat: "d-mm-yy",
            inline: true,
            onSelect: function (dateText) {
                var dateVal = $(this).val();
                $('.events-filter .date-filter input[name=date]').val(dateVal);
                $('.events-filter .date-filter .date-show i').text(dateVal);
                $eventDatePicker.hide();
            }
        });
    }

    function setDropdownValues(property) {
        if (property != null && property != undefined && property != '') {
            if ($('.events-filter select[id=property] option[value=' + property + ']').length > 0) {
                $('.events-filter select[id=property]').val(property);
                $('.events-filter select[id=property] option').removeClass('selected');
                $('.events-filter select[id=property] option[value=' + property + ']').addClass('selected');
                $('.property-dropdown .cab-select-box').text($('.property-dropdown li[rel=' + property + ']').text())
            }
        } else {
	        $('.placeholder-content').show();
        }
    }
    


    function setDatePickerValue() {
        var parsedDate = $.datepicker.parseDate("dd-mm-yy", selectedDate);
        $('.events-filter #events-date-picker').datepicker("setDate", parsedDate);
    }

    function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

});