$(function () {
    if ($('.location').length == 0) return false;

    var $propertySelector = $('.location .property-selector');

    /* Map Height */
    var height = $(window).height();
    $('#LocationsMap').height(height);
    $propertySelector.css('height', height + 'px');

    /* Tabs Height */
    var $children = $('.location .tabs').children();
    var tabHeight = 100;

    if (window.matchMedia('(min-width: 768px) and (max-width: 1000px)').matches) {
        tabHeight = (100 / ($children.length / 2));
    }
    else {
        tabHeight = (100 / $children.length);
    }
    $children.css('height', tabHeight + '%');

    /* Property Selector Dropdown Tabs */
    var $tabs = $('.location .property-selector').find('.tabs');
    var $contents = $('.location .property-selector .locations');
    $propertySelector.tabbed({
        tabs: $tabs,
        initialTab: null,
        contents: $contents,
        afterChange: function () {
            $propertySelector.removeClass('tabs-visible').removeClass('all-visible');

            var label = $tabs.find('.visible .middle').html();
            var type = label.replace(/(<b>.*?<\/b>)/igm, '').trim();
            $('.tabs-button span:nth-child(2)').text(type);

            deleteMarkers();

            bounds = new google.maps.LatLngBounds();
            addMarkers($contents.find('.visible'));

            map.fitBounds(bounds);
            if (markers.length > 1) {
            }
            else {
                map.setZoom(14);
            }
        }
    });

    /* Property Selector Button */
    $('.tabs-button').click(function () {
        $('.location .property-selector').toggleClass('tabs-visible');
        $('.locations-list').get(0).slick.setPosition();
    });

    $('.location .tabs li').click(function () {
        $('.locations-list').slick('setPosition');
        $('.locations-list').resize();
    });

    /* All Locations */
    var $allLocations = $('.location .all-locations');
    $('.location .locations [data-type="property"]').each(function () {
        $allLocations.append($(this).clone());
    });

    /* Multiple Product Tabs */
    function tabEvents() {
        $('.product-tabs span').on('click', function () {
            $(this).parent().find('span').removeClass('active');
            $(this).addClass('active');
            var $content = $(this).parent().next('.product-tabs-content');
            $content.find('> div').removeClass('active');
            $content.find('> div:nth-child(' + ($(this).index() + 1) + ')').addClass('active');
        });
    }
    tabEvents();

    /* Logo Carousel */
    $('.location .all-locations, .location .locations > ul').slick({
        centerMode: true,
        slidesToShow: 1,
        infinite: false,
        touchThreshold: 25,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '2%'
                }
            },
            {
                breakpoint: 480,
                settings: {
                    centerPadding: '7%'
                } 
            },
            {
                breakpoint: 768,
                settings: {
                    centerPadding: '15%'
                } 
            },
            {
                breakpoint: 1000,
                settings: 'unslick'
            }
        ],
        centerPadding: '2%'
    });

    /* Logo Click: Centre on Map */
    $('.locations-list').on('click', 'li', function () {
        var position = { lat: parseFloat($(this).attr('data-lat')), lng: parseFloat($(this).attr('data-lng')) };
        map.setCenter(position);
        map.setZoom(13);
    });


    /*
     * ----------------------------------------
     * Google Maps
     * ----------------------------------------
     */
    var map,
        bounds = null
        markers = [],
        ib = null;

    /* Init Map */
    var initMap = function () {
        
        var center = { lat: 51.507302, lng: -0.127429 };
        var zoom = 9
        var singleProperty = false;

        if (typeof initialLat != "undefined" && typeof initialLng != "undefined") {
            center = { lat: initialLat, lng: initialLng };
            zoom = 15;
            singleProperty = true
        }

        map = new google.maps.Map(document.getElementById('LocationsMap'), {
            zoom: zoom,
            center: center,
            disableDefaultUI: true,
            scrollwheel: false,
            draggable: (window.matchMedia('(max-width: 1000px)').matches ? false : true)
        });

       

        /* Window Resize Event */
        google.maps.event.addDomListener(window, 'resize', function () {
            map.fitBounds(bounds);
            closeInfoBox();
            $('#LocationsMap, .location .property-selector, .finduslocation .property-selector').outerHeight($(window).height() - $('#TopNav').height());
        });

        if (!singleProperty) {
            bounds = new google.maps.LatLngBounds();
            map.fitBounds(bounds);
        }

        addMarkers($('.location .locations'), 'property');

        map.fitBounds(bounds);

    }();

    /* Add Markers Based on Visible Data Attributes */
    function addMarkers($container, type) {

        $container.find('[data-lat]' + (typeof type !== 'undefined' ? '[data-type="' + type + '"]' : '')).each(function () {

            var iconWidth = 37;

            var position = { lat: parseFloat($(this).attr('data-lat')), lng: parseFloat($(this).attr('data-lng')) };
            var marker = new google.maps.Marker({
                position: position,
                map: map,
                icon: new google.maps.MarkerImage($(this).attr('data-marker'), null, null, null, new google.maps.Size(iconWidth, 46)),
                html: $(this).html(),
                pos: position,
                loc: $(this).attr('data-location')
            });
            marker.addListener('click', function () {
                closeInfoBox();

                if (window.matchMedia('(min-width: 1001px)').matches) {
                    var ibOptions = {
                        content: this.html,
                        alignBottom: true,
                        disableAutoPan: false,
                        maxWidth: 0,
                        pixelOffset: new google.maps.Size(20, 250),
                        zIndex: null,
                        closeBoxURL: "",
                        infoBoxClearance: new google.maps.Size(50, 50),
                        isHidden: false,
                        pane: "floatPane",
                        enableEventPropagation: false
                    };
                    ib = new InfoBox(ibOptions);
                    ib.open(map, this);
                    ib.addListener("domready", function () {
                        $('.infoBox .close').on('click', function (e) {
                            $(this).closest('.infoBox').css('visibility', 'hidden');
                        });
                        tabEvents();
                    });
                }
                else {
                    $('.location .locations-list li[data-lat="' + this.pos.lat + '"][data-lng="' + this.pos.lng + '"]').each(function () {
                        var $slider = $(this).closest('.slick-slider');
                        if ($slider.length > 0) {
                            //$('html, body').animate({ scrollTop: $('.locations-list').position().top + 325}, 600);
                            $slider.slick('slickGoTo', $(this).attr('data-slick-index'));
                        }
                    });
                }
            });

            markers.push(marker);
            if (bounds !== null) {
                bounds.union(new google.maps.LatLngBounds(new google.maps.LatLng(position.lat, position.lng)));
            }
        });
    }

    /* Close Info Box */
    function closeInfoBox() {
        if (ib !== null) {
            ib.close();
            ib = null;
        }
    }

    /* Delete All Map Markers */
    function deleteMarkers() {
        closeInfoBox();
        for (var i = 0; i < markers.length; i++) {
            markers[i].setMap(null);
        }
        markers = [];
    }

    /* Pre Select a Property */
    var preSelect = $('#LocationsMap').data('pre-select');
    if (preSelect !== '') {
        var $property = $('.all-locations [data-node="' + preSelect + '"]');
        if ($property.length > 0) {
            var $slide = $property.closest('li');

            if (window.matchMedia('(max-width: 1000px)').matches) {
                $('.property-selector').removeClass('tabs-visible');
            }

            if ($slide.length > 0) {
                for (var i in markers) {
                    if (markers[i].loc == $slide.data('location')) {
                        new google.maps.event.trigger(markers[i], 'click');
                        break;
                    }
                }
            }
        }
    }

});