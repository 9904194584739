$(function () {

    if ($('.faq').length == 0) return false;

    /* Tabs */
    var $list = $('.faq section');
    var $tabsContainer = $list.find('.tabs');
    var $contents = $list.find('.faq-categories');
    var width = 33.33;

    $list.tabbed({
        tabs: $tabsContainer,
        contents: $contents,
        afterChange: function () {
            if (window.matchMedia('(min-width: 768px)').matches) {
                var $cat = $('.faq-categories .faq-cat.visible');
                $('html, body').animate({ scrollTop: $cat.position().top }, 600);
            }
            var $tab = $tabsContainer.find('.visible');
            $tabsContainer.css('margin-left', -((width * $tab.index()) - width) + '%');
        }
    });

    /* Swipe Event */
    $list.cabSwipe({
        left: function () {
            $list.trigger('next');
        },
        right: function () {
            $list.trigger('prev');
        }
    });

    var $tabs = $tabsContainer.find('li');
    $tabsContainer.css('width', width * $tabs.length + '%').css('margin-left', width + '%');
    $tabs.css('width', (100 / $tabs.length) + '%');

    /* Question Click Event */
    $('.faq-list').on('click', 'dt', function () {
        $(this).toggleClass('visible');
        $(this).next().toggleClass('visible').slideToggle();
    });


    /* Sticky */
    var stickyScroll = null;
    function sticky() {
        $('aside .sticky').each(function () {
            var thisHeight = $(this).outerHeight();
            var scroll = $(window).scrollTop();

            $(this).css('top', (($(window).height() / 2) + scroll) - (thisHeight / 2));

            var offset = thisHeight + $(this).position().top;

            if (offset >= $(this).parent().height()) {
                $(this).addClass('bottom');

                if (stickyScroll == null) {
                    stickyScroll = scroll;
                }
                else {
                    if (scroll < stickyScroll) {
                        $(this).removeClass('bottom');
                        stickyScroll = null;
                    }
                }
            }
            else {
                $(this).removeClass('bottom');
            }
        });
    };
    sticky();
    $(window).on('scroll resize', function () {
        sticky();
    });

    $('.arrow').append('<i></i><i></i>');

});