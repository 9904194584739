$(function () {

    //$("#HolesListData div.holeItem").each(function (index) {
    //    if (index != 0) {
    //        $(this).hide();
    //    } else {
    //        $(this).addClass("selected");
    //    }
        
    //});
    

    //$("#HolesList li").click(function () {
    //    var holeId = $(this).data("hole");
    //    $("#HolesList li").each(function (index) {
    //        $(this).removeClass("selected");
    //    });

    //    $(this).addClass("selected");
        

    //    $("#HolesListData div.holeItem").each(function (index) {
    //        $(this).hide();
    //    });
    //    $(".hole" + holeId).show();

    //});

});