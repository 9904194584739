(function ($) {

    var cabSwipeClass = function ($elem, settings) {

        var start;

        $elem.bind('touchstart mousedown', start);

        function start(event) {
            var touches = event.originalEvent.touches;
            if (touches && touches.length || event.originalEvent.clientX) {
                start = (touches ? touches[0].pageX : event.originalEvent.clientX);
                $elem.bind('touchmove mousemove', move);
                $elem.bind('touchend', end);

                $elem.bind('mouseup', end);
            }
            //event.preventDefault();
        }

        function move(event) {
            var touches = event.originalEvent.touches;

            if (touches && touches.length || event.originalEvent.clientX) {
                var distance = start - (touches ? touches[0].pageX : event.originalEvent.clientX);

                if (distance >= settings.threshold) {
                    settings.left();
                }
                if (distance <= -Math.abs(settings.threshold)) {
                    settings.right();
                }

                if (Math.abs(distance) >= settings.threshold) {
                    $elem.unbind('touchmove mousemove', move);
                    $elem.unbind('touchend', end);
                }
            }
            //event.preventDefault();
        }

        function end(event) {
            $elem.unbind('touchmove mousemove', move);
            //event.preventDefault();
        }

    };

    $.fn.cabSwipe = function (options) {
        var opts = $.extend({}, $.fn.cabSwipe.defaults, options);
        return this.each(function () {
            new cabSwipeClass($(this), opts);
        });
    };

    $.fn.cabSwipe.defaults = {
        threshold: 50,
        left: function () { },
        right: function () { }
    };

})(jQuery);