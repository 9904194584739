$(function () {

    if ($('.stretch').length > 0) {
        var resizeListener = function () {
            $(window).one('resize', function () {
                onWindowResize();
                setTimeout(resizeListener, 100);
            });
        };
        $(window).on("orientationchange", function (event) {
            onWindowResize();
        });

        onWindowResize();
        resizeListener();
    }


    function onWindowResize() {

        var width = $(window).width();
        var size = 1280;

        //if (width < 768) {
        //    size = 480;
        //}
        //else if (width < 1000) {
        //    size = 768;
        //}
        //else {
        //    size = 1280;
        //}
       

        if (width < 500) {
            size = 320;
        }
        else if (width < 768) {
            size = 480;
        }
        else if (width < 1024) {
            size = 768;
        } else if (width < 1500) {
            size = 1024
        }
        
        $('.stretch').each(function () {
            var s = '';
            var $this = $(this);

            if (size == 1280) {
                var attr = $this.attr('data-largedesktop');
                if (attr) {
                    if (isRetina() && typeof $this.attr("data-desktop-retina") !== 'undefined' && typeof $this.attr("data-desktop-retina") !== "") {
                        s = "largedesktop-retina"
                    } else {
                        s = "largedesktop"
                    }
                } else {
                    if (isRetina() && typeof $this.attr("data-desktop-retina") !== 'undefined' && typeof $this.attr("data-desktop-retina") !== false) {
                        s = "desktop-retina";
                    } else {
                        s = "desktop";
                    }
                }
            } else if (size == 1024) {
                if (isRetina() && typeof $this.attr("data-desktop-retina") !== 'undefined' && typeof $this.attr("data-desktop-retina") !== "") {
                    s = "desktop-retina";
                } else {
                    s = "desktop";
                } 
            } else if (size == 768) {
                if (isRetina() && typeof $this.attr("data-tablet-retina") !== 'undefined' && typeof $this.attr("data-tablet-retina") !== "") {
                    s = "tablet-retina";
                } else {
                    s = "tablet";
                }
            } else if (size == 480) {

                var phablet = $this.attr('data-phablet');

                if (phablet) {
                    if (isRetina() && typeof $this.attr("data-phablet-retina") !== 'undefined' && typeof $this.attr("data-phablet-retina") !== "") {
                        s = "phablet-retina";
                    } else {
                        s = "phablet";
                    }
                } else {
                    if (isRetina() && typeof $this.attr("data-mobile-retina") !== 'undefined' && typeof $this.attr("data-mobile-retina") !== "") {
                        s = "mobile-retina";

                    } else {
                        s = "mobile";
                    }
                }
            } else if (size == 320) {

                if (isRetina() && typeof $this.attr("data-mobile-retina") !== 'undefined' && typeof $this.attr("data-mobile-retina") !== "") {
                    s = "mobile-retina";

                } else {
                    s = "mobile";
                }

            }


            if ($this.attr('data-' + s)) {
                $this.css('background-image', 'url(' + $this.attr('data-' + s) + ')').css('background-size', 'cover').css('background-position', 'center center');
            }
        });

        function isRetina() {
            return ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)').matches)) || (window.devicePixelRatio && window.devicePixelRatio > 1.3));
        }

    }


    /* Grid height fix */
    //var gridTimer;
    //function resizeGrids() {
    //    if ($(window).width() > 768) {

    //        $('.container > .row').each(function () {

    //            if ($(this).find('.grid-property').length === 0) {
    //                var max = 0;
    //                var $cols = $(this).find('.column');
    //                if ($cols.length > 1) {
    //                    $cols.each(function () {
    //                        var h = $(this).outerHeight(true);
    //                        max = h > max ? h : max;
    //                    });

    //                    $cols.each(function () {
    //                        $(this).height(max).addClass('height-equal');
    //                    });
    //                }
    //            }
    //        });

    //        var introBlock = $('.intro-text');
    //        introBlock.siblings('.grid-image').height(introBlock.outerHeight());

    //    } 
    //};

    //setTimeout(function () {
    //    resizeGrids();
    //}, 500);

    //$(window).resize(function () {
    //    clearTimeout(gridTimer);
    //    gridTimer = setTimeout(resizeGrids, 250);
    //});
});