$(function() {
    if ($('.contact').length == 0) return false;

    /* Property Carousel's */
    var $carousels = $('.contact .category .properties');
    $carousels.each(function() {
        var children = $(this).children().length;
        if (children >= 3) {
            $(this).addClass('padding');
        }
        $(this).slick({
            infinite: false,
            touchThreshold: 25,
            mobileFirst: true,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 0,
                    settings: {
                        slidesToShow: 1,
                        centerMode: true,
                        centerPadding: '20%'
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: children < 3 ? children : 3,
                        centerMode: false
                    }
                },
                {
                    breakpoint: 1280,
                    settings: 'unslick'
                }
            ]
        });
    });

    /* Property Click Event */
    $('.contact').on('click', '.property-details', function myfunction() {
        location.hash = $(this).attr('data-hash');
    });

    $('.property-details').hover(
        function() {
            $(this).addClass('active');
            $('.property-details').each(function() {
                if (!$(this).hasClass('active')) {
                    $(this).addClass('not-active');
                }
            });
        },
        function() {
            $(this).removeClass('active');
            $('.property-details').each(function() {
                $(this).removeClass('not-active');
            });
        }
    );

    /* Header Buttons */
    $('.contact #MakeEnquiryButton').click(function() {
        location.hash = 'make-enquiry';
    });
    $('.contact #BackButton').click(function() {
        location.hash = '';
    });

    $('a[data-action="mobile-enquiry"]').click(function(e) {
        var hash = $('.category.visible .slick-current').attr('data-hash');
        if (hash !== '') {
            e.preventDefault();
            location.hash = hash;
        }
    });

    /* Conditional reasons based on selected hotel */
    $('select[name="3da1c0de-0149-4a97-e2ea-9f35c77dfba9"], .contact-form__picker input[type="radio"]').on('change', function(e) {
        var selectedHotel = $(this).val();

        $.get('/Umbraco/Api/ContactReasons/GetContactReasonsForProperty?propertyName=' + selectedHotel, function(data) {
            var reasonsSelect = $('select[name="a9e2cc26-2ee4-4871-d621-954c2187f9ba"], select[name="4cd6ac88-f50e-4401-d512-6f505a877273"]');

            reasonsSelect.children('option:not([value=""])').remove();

            if (data) {
                if (reasonsSelect.children().length === 0) {
                    reasonsSelect.append($('<option></option>'));
                }

                $.each(data, function(i, item) {
                    var option = $('<option></option>')
                        .attr('value', item)
                        .text(item);

                    reasonsSelect.append(option);
                });
            }

            // reinitialise the dropdown
            reasonsSelect.siblings().remove();
            reasonsSelect.unwrap('.cab-select');
            reasonsSelect.cabSelect();
            reasonsSelect.parent('.cab-select').prepend('<i class="arrow"><i></i><i></i></i>');
        });
    });

    $('.contact-form__picker input[type="radio"]:checked').trigger('change');

    /* Handle Hash Change */
    function hashChange() {
        window.scroll(0, 0);
        var hash = window.location.hash.replace(/^#/, '');
        if (hash == '') {
            $('main').removeClass('form-visible');
            $('.contact .categories').show();
            $('.contact .form').hide();
            $carousels.slick('resize');
        } else {
            $('main').addClass('form-visible');
            if (hash == 'make-enquiry') {
                $('.contact .categories').hide();
                $('.contact .form').show();
                return true;
            }
            var $elem = $('.property-details[data-hash="' + hash + '"]');
            if ($elem.length > 0) {
                var name = $elem.attr('data-name');
                var $select = $('option[value="' + name + '"]').parent();
                if ($select.length > 0) {
                    $select.val(name).change();
                }
            }
            $('.contact .categories').hide();
            $('.contact .form').show();
        }
    }
    hashChange();
    $(window).bind('hashchange', function() {
        hashChange();
    });

    /* Tabs */
    var $contact = $('.contact');
    var $tabsContainer = $contact.find('.tabs');
    var $contents = $contact.find('.categories');
    var width = 33.33;

    $contact.tabbed({
        tabs: $tabsContainer,
        contents: $contents,
        afterChange: function() {
            $carousels.slick('resize');
            if (window.matchMedia('(min-width: 768px)').matches) {
                var $cat = $('.contact .categories .category.visible');
                $('html, body').animate({ scrollTop: $cat.position().top - 100 }, 600);
            }
            var $tab = $tabsContainer.find('.visible');
            $tabsContainer.css('margin-left', -(width * $tab.index() - width) + '%');
        }
    });

    var $tabs = $tabsContainer.find('li');
    $tabsContainer.css('width', width * $tabs.length + '%').css('margin-left', width + '%');
    $tabs.css('width', 100 / $tabs.length + '%');

    /* Swipe Event */
    $tabs.cabSwipe({
        left: function() {
            $contact.trigger('next');
        },
        right: function() {
            $contact.trigger('prev');
        }
    });

    /* Hover panels for mice only */
    $('.contact-grid__item').on('mouseenter', function() {
        if (!$('html').hasClass('touch')) {
            $(this).addClass('show-details');
        }
    });

    $('.contact-grid__item').on('mouseleave', function() {
        if (!$('html').hasClass('touch')) {
            $(this).removeClass('show-details');
        }
    });

    // Manual controls for touchscreen
    $('.contact-grid__item').append(
        '<ul class="contact-grid__pagination"><li class="current"><button>1</button></li><li><button>2</button></li></ul>  <button class="contact-grid__toggle">Toggle</button>'
    );

    if ($('html').hasClass('touch')) {
        $('.contact-grid__item').on('click', function() {
            if ($(this).hasClass('show-details')) {
                // details already shown, so hide them
                $(this).removeClass('show-details');
            } else {
                // Details not visible, so close any open ones and then open this specific one
                $('.contact-grid__item').removeClass('show-details');
                $(this).addClass('show-details');
            }
        });
    }

    // Property filters
    var $_filterButtons = $('.contact-filters__buttons button');
    var $_filterItems = $('.contact-grid__item');

    $_filterButtons.on('click', function() {
        $('.contact-filters__buttons button.on').removeClass('on');
        $(this).addClass('on');

        // Clear the "All" button
        if (!$(this).hasClass('all')) {
            $('.contact-filters__buttons button.all').removeClass('on');

            // Change the dropdown for mobile filtering
            $('.contact-filters__dropdown select').val($(this).data('category'));
            $('.cab-select-box').text(
                $(this)
                    .data('category')
                    .replace('-', ' ')
            );
        } else {
            // All button has been clicked, so show all buttons and return
            $('.contact-filters__buttons button').removeClass('on');
            $('.contact-filters__buttons button.all').addClass('on');

            // Change the dropdown for mobile filtering
            $('.contact-filters__dropdown select').val('all');
            $('.cab-select-box').text('Select filter');
            $_filterItems.fadeIn();
            return;
        }

        var currentCats = '';

        // Loop through the "on" buttons and build a class selector string of the active
        $_filterButtons.each(function() {
            if ($(this).hasClass('on')) {
                currentCats += '.' + $(this).data('category') + ', ';
            }
        });
        currentCats = currentCats.substr(0, currentCats.length - 2); // Remove the last comma and space from the string, otherwise jQuery borks

        // No categories selected so show them all
        if (currentCats == '') {
            $_filterItems.fadeIn();
            return;
        }

        // Hide or show the elements that match the class
        $_filterItems.each(function() {
            if (!$(this).is(currentCats)) {
                $(this).fadeOut();
            }
        });

        setTimeout(function() {
            $_filterItems.each(function() {
                if ($(this).is(currentCats)) {
                    $(this).fadeIn();
                }
            });
        }, 500);
    });

    // Update the buttons when the mobile filter dropdown is changed
    $('.contact-filters__dropdown select').on('change', function() {
        if ($(this).val() === '') {
            $('.contact-filters__buttons button.all').trigger('click');
        } else {
            $('.contact-filters__buttons button[data-category="' + $(this).val() + '"]').trigger('click');
        }
    });

    // Select the property and update telephone & satnav info
    $('.contact-form__picker-dropdown input[type="radio"]').on('change', function(e) {
        var propertyName = $(this).data('name');
        var pickerClass = propertyName.replace(/ /g, '');
        $('#contact-form__picker-name').text($(this).data('name'));
        $('#contact-form__picker-name')
            .parent()
            .removeClass();
        $('#contact-form__picker-name')
            .parent()
            .addClass('contact-form__picker');
        $('#contact-form__picker-name')
            .parent()
            .addClass(pickerClass);
        $('#contact-form__picker-telephone').text('Telephone: ' + $(this).data('telephone'));
        $('#contact-form__picker-satnav').html('<a href="' + $(this).data('map-link') + '" target="_blank">Sat nav: ' + $(this).data('satnav') + '</a>');
        $('.contact-form__picker').removeClass('open');
        $('.contact-form__picker-dropdown').slideUp();
    });

    // Pre-select the right hotel
    $checkedHotel = $('.contact-form__picker-dropdown input[type="radio"]:checked');
    if (typeof $checkedHotel.val() != 'undefined') {
        $('#contact-form__picker-name').text($checkedHotel.data('name'));
        $('#contact-form__picker-telephone').text('Telephone: ' + $checkedHotel.data('telephone'));
        $('#contact-form__picker-satnav').html('<a href="' + $checkedHotel.data('map-link') + '" target="_blank">Sat nav: ' + $checkedHotel.data('satnav') + '</a>');
    }

    // Hotel choice dropdown picker
    $('.contact-form__picker > label, .contact-form__picker span, .contact-form__picker button').on('click', function(e) {
        e.preventDefault();
        $(this).toggleClass('open');
        $('.contact-form__picker-dropdown').slideToggle();
    });

    // Prevent submitting without choosing a hotel
    $('.contact-form').on('submit', function() {
        if (typeof $('.contact-form__picker-dropdown input[type="radio"]:checked').val() === 'undefined') {
            $('.contact-form__picker').addClass('open');
            $('.contact-form__picker-dropdown').slideDown();

            $('html, body').animate(
                {
                    scrollTop: $('.contact-form__picker').offset().top - 100
                },
                1000
            );

            return false;
        }
    });
});
