$(function() {
    $(window).bind('scroll', function(e) {
        if ($(window).scrollTop() > 220) {
            $('#TopNav').addClass('thin');
        } else {
            $('#TopNav').removeClass('thin');
        }
    });

    function detectIE() {
        var ua = window.navigator.userAgent;

        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        return false;
    }

    if (detectIE() !== false) {
        $('html').addClass('msie');
    }

    function is_touch_device() {
        return 'ontouchstart' in window || 'onmsgesturechange' in window;
    }

    /*
     * ----------------------------------------
     * Sign Up
     * ----------------------------------------
     */
    $('.sign-up').click(function(e) {
        e.preventDefault();
        $form = $('.sign-up-form');
        if (!$form.hasClass('visible')) {
            anyVisible(showSignUp);
        } else {
            hideSignUp();
            showArrow();
        }
    });

    /* Signup dropdown close */
    $('.sign-up-form .icon-close').click(function() {
        hideSignUp();
    });

    /* Show and Hide Sign Up */
    function showSignUp() {
        $('.sign-up-form')
            .addClass('visible')
            .slideDown(400);
    }
    function hideSignUp() {
        $('.sign-up-form')
            .removeClass('visible')
            .slideUp(400);
    }

    /*
     * ----------------------------------------
     * Sign In
     * ----------------------------------------
     */
    $('.sign-in').click(function(e) {
        e.preventDefault();
        $form = $('.sign-in-form');
        if (!$form.hasClass('visible')) {
            anyVisible(showSignIn);
        } else {
            hideSignIn();
            showArrow();
        }
    });

    /* Show and Hide Sign In */
    function showSignIn() {
        $('.sign-in-form')
            .addClass('visible')
            .slideDown(400);
    }
    function hideSignIn() {
        $('.sign-in-form')
            .removeClass('visible')
            .slideUp(400);
    }

    /*
     * ----------------------------------------
     * Search
     * ----------------------------------------
     */
    $('#SearchButton').click(function(e) {
        e.preventDefault();
        $form = $('.search-form');
        if (!$form.hasClass('visible')) {
            anyVisible(showSearch);
        } else {
            hideSearch();
            showArrow();
        }
    });

    /* Show and Hide Search */
    function showSearch() {
        $('.search-form')
            .addClass('visible')
            .slideDown(400);
    }
    function hideSearch() {
        $('.search-form')
            .removeClass('visible')
            .slideUp(400);
    }

    /*
     * ----------------------------------------
     * Contact
     * ----------------------------------------
     */
    //$('#ContactButton').click(function (e) {
    //    e.preventDefault();
    //    $contactDropdown = $('#ContactDropdown');

    //    if ($(window).width() < 768) {
    //        var ContactDDheight = $(window).height() - 45;
    //        $('#ContactDropdown').height(ContactDDheight)
    //    }

    //    if (!$contactDropdown.hasClass('visible')) {
    //        anyVisible(showContact);
    //        $('.siteWrap, body').addClass('lock');
    //    }
    //    else {
    //        hideContact();
    //        showArrow();
    //        $('.siteWrap, body').removeClass('lock');
    //    }
    //});

    // Close contact dropdown is click outside of container
    //$(document).click(function (event) {
    //    if (!$(event.target).closest('#ContactDropdown').length &&
    //       !$(event.target).is('#ContactDropdown')) {
    //        if ($('#ContactDropdown').is(":visible")) {
    //            hideContact();
    //            showArrow();
    //            $('.siteWrap, body').removeClass('lock');
    //        }
    //    }
    //})

    var resizeTimer;
    function resizeContactTab() {
        if ($(window).width() < 768) {
            var ContactDDheight = $(window).height() - 45;
            $('#ContactDropdown').height(ContactDDheight);
        }
    }
    $(window).resize(function() {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(resizeContactTab, 250);
    });

    /* Show and Hide Contact Dropdown */
    function showContact() {
        $contactDropdown = $('#ContactDropdown');
        $contactDropdown.show();
        $contactDropdown.find('.contacts').height($contactDropdown.find('.contacts .visible').height());
        $contactDropdown.hide();
        $contactDropdown.addClass('visible').slideDown(400);
    }
    function hideContact() {
        $contactDropdown.removeClass('visible').slideUp(400);
    }

    /* Contact Dropdown Tabs */
    var width = 33.33;
    $('#ContactDropdown').tabbed({
        afterChange: function() {
            $('#ContactDropdown .contacts').height($('#ContactDropdown .contacts .visible').height());

            var $tabsContainer = $contactDropdown.find('.tabs');
            var $tab = $tabsContainer.find('.visible');
            $tabsContainer.css('margin-left', -(width * $tab.index() - width) + '%');
        }
    });
    var $contactDropdown = $('#ContactDropdown');
    var $tabsContainer = $contactDropdown.find('.tabs');
    var $tabs = $tabsContainer.find('li');
    $tabsContainer.css('width', width * $tabs.length + '%').css('margin-left', width + '%');
    $tabs.css('width', 100 / $tabs.length + '%');

    /* Contact Dropdown Swipe */
    $contactDropdown.cabSwipe({
        left: function() {
            $contactDropdown.trigger('next');
        },
        right: function() {
            $contactDropdown.trigger('prev');
        }
    });

    /*
     * ----------------------------------------
     * Book Now
     * ----------------------------------------
     */

    $('#BookButton').click(function(e) {
        if ($(this).attr('href') == '') {
            e.preventDefault();

            if (typeof ga !== 'undefined' && typeof ga.getAll !== 'undefined' && ga.getAll().length > 0) {
                var clientId = ga.getAll()[0].get('clientId');
                $('input[name="bookingModel._ga"]')
                    .val(clientId)
                    .change();
            }

            if (!$('#BookNow').hasClass('visible')) {
                anyVisible(showBookNow);
                if ($(window).width() < 768) {
                    $('#BookNow .tabs').removeClass('active');
                } else {
                    //$('#MenuButton').addClass('hide');
                }
            } else {
                hideBookNow();
                showArrow();
                //$('#MenuButton').removeClass('hide');
                $('.siteWrap, body').removeClass('lock');
            }
        }
    });

    /*
     * ----------------------------------------
     * Tablet & Mobile Navigation
     * ----------------------------------------
     */
    $('#PrimaryNavButton').click(function() {
        var $TopNav = $('#TopNav');

        if (!$TopNav.hasClass('visible')) {
            anyVisible(showNavigation);
            $(this).addClass('active');
        } else {
            hideNavigation();
            showArrow();
            $(this).removeClass('active');
        }
    });

    /* Show and Hide Navigation */
    function showNavigation() {
        var $TopNav = $('#TopNav');
        if ($(window).width() < 1200) {
            $TopNav.addClass('visible');
            $('body, .siteWrap').addClass('lock');
        } else if ($(window).width() < 1099) {
            //$TopNav.addClass('visible');
        }
    }

    function hideNavigation() {
        var $TopNav = $('#TopNav');
        if ($(window).width() < 1200) {
            $TopNav.removeClass('visible');
            $('body, .siteWrap').removeClass('lock');
        } else if ($(window).width() < 1099) {
            //$TopNav.removeClass('visible');
        }
    }

    /*
     * ----------------------------------------
     * Scroll Event for Thin Nav Bar
     * ----------------------------------------
     */
    $body = $('body');
    $(window).bind('scroll', function(e) {
        if ($(window).scrollTop() > 100) {
            $body.addClass('thin-nav');
        } else {
            $body.removeClass('thin-nav');
        }
    });

    /*
     * ----------------------------------------
     * Helper Methods
     * ----------------------------------------
     */

    /* Any Drop Dropdowns Visible? */
    function anyVisible(func, noArrowHide) {
        var elems = ['#BookNow', '#ContactDropdown', '.search-form', '.sign-up-form', '.sign-in-form', '#SideNav'];
        for (var e in elems) {
            if ($(elems[e]).hasClass('visible')) {
                hideVisible();
                if (noArrowHide) {
                    showArrow();
                } else {
                    hideArrow();
                }
                setTimeout(function() {
                    func();
                }, 400);
                return true;
            }
        }
        if (noArrowHide) {
            showArrow();
            func();
        } else {
            hideArrow();
            setTimeout(function() {
                func();
            }, 400);
        }
    }

    /* Hide Any Visible Dropdowns */
    function hideVisible() {
        hideSignIn();
        hideSignUp();
        hideSearch();
        hideContact();
        hideBookNow();
        hideNavigation();
    }

    /* Hide and Show Arrow */
    function hideArrow() {
        $('#TopNav').addClass('button-hidden');
    }
    function showArrow() {
        setTimeout(function() {
            $('#TopNav').removeClass('button-hidden');
        }, 400);
    }
});

/* Show and Hide Book Now */
function showBookNow() {
    $('#BookButton').addClass('book-now-visible');
    $('#BookNow').addClass('visible');
}
function hideBookNow() {
    var $bookNow = $('#BookNow');
    var $bookNowButton = $('#BookButton');
    var $forms = $bookNow.find('.forms');
    var $visible = $bookNow.find('.visible');

    if ($visible.length > 0) {
        $forms.slideUp();
        setTimeout(function() {
            $visible.removeClass('visible');
            $bookNow.removeClass('visible');
            $bookNowButton.removeClass('book-now-visible');
        }, 400);
        //$('body').removeClass('mob-lock');
    } else {
        $bookNow.removeClass('visible');
        $bookNowButton.removeClass('book-now-visible');
    }
}

$(function() {
    $('#Footer > ul.row').equalColumns({
        breakpoints: {
            0: 1,
            768: 3
        }
    });

    var mobileMedia = function(media) {
        if (media.matches) {
            $('.fixed-bottom, #SideNav')
                .removeAttr('style')
                .removeClass('visible');
            $('.voucher-link').before($('.secondary-nav'));

            /* Resizing Height of Book now nav height on mobile */

            (function($) {
                var resizeTimer;
                function resizeNavTabs() {
                    var topNavHeight = $('#TopNav').height();
                    var tabHeight = $(window).height();
                    if ($('.tab-contain').hasClass('visible')) {
                        $('.tab-contain').height(tabHeight - topNavHeight);
                        $('#siteWrap').addClass('lock');
                    }
                }
                $(window).resize(function() {
                    clearTimeout(resizeTimer);
                    resizeTimer = setTimeout(resizeNavTabs, 250);
                });
            })(jQuery);
        }
    };

    var tabletMedia = function(media) {
        if (media.matches) {
            $('.fixed-bottom, #SideNav')
                .removeAttr('style')
                .removeClass('visible');
            $('.voucher-link').before($('.secondary-nav'));

            (function($) {
                var resizeTimer;
                function resizeNavTabs() {
                    var topNavHeight = $('#TopNav').height();
                    var tabHeight = $(window).height();
                    if ($('.tab-contain').hasClass('visible')) {
                        $('.tab-contain').height(tabHeight - topNavHeight);
                        $('#siteWrap').addClass('lock');
                    }
                }
                $(window).resize(function() {
                    clearTimeout(resizeTimer);
                    resizeTimer = setTimeout(resizeNavTabs, 250);
                });

                var navWidth;

                $('.primary-nav > li').each(function() {
                    navWidth += $(this).width();
                    return navWidth;
                });
            })(jQuery);
        }
    };

    var desktopMedia = function(media) {
        if (media.matches) {
            $('.fixed-bottom, #SideNav')
                .removeAttr('style')
                .removeClass('visible');
            $('.fixed-bottom .social-icons').before($('.secondary-nav'));
        }
    };

    var mobile = window.matchMedia('(max-width: 767px)');
    mobileMedia(mobile);
    mobile.addListener(mobileMedia);

    var tablet = window.matchMedia('(min-width: 768px) and (max-width: 1000px)');
    tabletMedia(tablet);
    tablet.addListener(tabletMedia);

    var desktop = window.matchMedia('(min-width: 1001px)');
    desktopMedia(desktop);
    desktop.addListener(desktopMedia);
});
